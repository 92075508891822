import React from "react"
import Layout from "../components/Layout"

export default function PageNotFound() {
  return (
    <Layout>
      <h1>Jings, that's no right.</h1>
      <p>Sorry, this page couldn't be found.</p>
    </Layout>
  )
}
